import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import * as yup from "yup";
import useActiveYard from "../../hooks/useActiveYard";
import { CartItem } from "../../typing/gql.schema";

const validationSchema = yup.object({
  price: yup.number().min(0).required("Required"),
  quality: yup.number().min(1).max(3).required("Required"),
});

export type BidFormValues = yup.InferType<typeof validationSchema>;

type Props = {
  item: CartItem;
  onCancel: () => void;
  onSubmit: (values: BidFormValues) => void;
};

const useBidForm = ({ item, onCancel, onSubmit }: Props) => {
  const yard = useActiveYard();
  const { rfqId } = useParams();
  const [step, setStep] = useLocalStorage(`${yard?.id}y${rfqId}${item.id}-bidding-form-step`, 0);
  const [storedFormValues, setStoredFormValues] = useLocalStorage(`${yard?.id}y${rfqId}${item.id}-bidding-form`, {
    price: 0,
    quality: 2,
  });

  const form = useFormik({
    onSubmit: (values) => {
      setStep(0);
      setStoredFormValues({ price: 0, quality: 2 });
      onSubmit(values);
    },
    validationSchema,
    initialValues: storedFormValues,
  });

  const cancel = () => {
    setStep(0);
    setStoredFormValues({ price: 0, quality: 2 });
    onCancel();
  };

  useEffect(() => {
    setStoredFormValues(form.values);
  }, [form.values]);

  return { ...form, step, setStep, cancel };
};

export default useBidForm;
