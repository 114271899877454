import { Button, Card, Group, NumberInput, Space, Table, Text } from "@mantine/core";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { FadeTransition } from "../../components/Transitions";
import useElementHover from "../../hooks/useElementHover";
import { CartItem } from "../../typing/gql.schema";
import { bidingItemTransition } from "../../utils/constants";
import { formatCurrency } from "../../utils/utils";
import useBidForm, { BidFormValues } from "../hooks/useBidForm";

type Props = {
  item: CartItem;
  onCancel: () => void;
  onSubmit: (values: BidFormValues) => void;
};

const CategoryItemBidForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { item, onCancel, onSubmit } = props;
  const form = useBidForm({ item, onSubmit, onCancel });
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const cancelButtonHover = useElementHover();

  return (
    <>
      <Conditional renderIf={isDesktop}>
        <AnimatePresence>
          <Tr {...bidingItemTransition} data-testid="category-item-bid-form-desktop">
            <Table.Td>
              <Text>
                {item?.plant?.latinName} {item?.notes}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.plant?.commonName}</Text>
            </Table.Td>
            <Table.Td>
              <Text>{item?.size?.name}</Text>
            </Table.Td>
            <Table.Td>
              <Text>
                {item?.quantity}{" "}
                <Conditional renderIf={form.values.price}>
                  {t("categoryItemBidFormText17")} {formatCurrency(form.values.price)} {t("categoryItemBidFormText18")}
                </Conditional>
              </Text>
            </Table.Td>
            <Table.Td>
              <Conditional renderIf={form.step === 0}>
                <FadeTransition transition={bidingItemTransition}>
                  <div className="actions">
                    <NumberInput
                      min={0}
                      step={1}
                      name="price"
                      decimalScale={2}
                      aria-label="price"
                      leftSection={<span>$</span>}
                      className="price-input"
                      onBlur={form.handleBlur}
                      value={form.values.price || undefined}
                      placeholder={t("categoryItemBidFormText1")!}
                      onChange={(value) => form.setFieldValue("price", value)}
                    />
                    <Space w={5} />
                    <Button.Group>
                      <Button onClick={() => form.handleSubmit()} disabled={!form.values.price}>
                        {t("categoryItemBidFormText3")}
                      </Button>
                      <Button
                        color="red"
                        ref={cancelButtonHover.ref}
                        variant={cancelButtonHover.hovered ? "filled" : "outline"}
                        onClick={form.cancel}
                      >
                        {t("categoryItemBidFormText5")}
                      </Button>
                    </Button.Group>
                  </div>
                </FadeTransition>
              </Conditional>
            </Table.Td>
          </Tr>
        </AnimatePresence>
      </Conditional>
      <Conditional renderIf={!isDesktop}>
        <FadeTransition transition={bidingItemTransition}>
          <Card data-testid="category-item-bid-form-mobile">
            <Text fw={700}>{t("categoryItemBidFormText6")}</Text>
            <Text>
              {item?.plant?.latinName} {item?.notes}
            </Text>
            <Space h={5} />
            <Text fw={700}>{t("categoryItemBidFormText7")}</Text>
            <Text>{item?.plant?.commonName}</Text>
            <Space h={5} />
            <Text fw={700}>{t("categoryItemBidFormText8")}</Text>
            <Text>{item?.size?.name}</Text>
            <Space h={5} />
            <Text fw={700}>{t("categoryItemBidFormText9")}</Text>
            <Text>
              {item?.quantity} {t("categoryItemBidFormText17")} {formatCurrency(form.values.price)}{" "}
              {t("categoryItemBidFormText18")}
            </Text>
            <Conditional renderIf={form.step === 0}>
              <FadeTransition transition={bidingItemTransition}>
                <Group justify="left">
                  <Text fw={700}>{t("categoryItemBidFormText10")}</Text>
                  <Space h={50} />
                </Group>
                <Group justify="center">
                  <NumberInput
                    min={0}
                    step={1}
                    name="price"
                    decimalScale={2}
                    aria-label="price"
                    leftSection={<span>$</span>}
                    className="input-item"
                    onBlur={form.handleBlur}
                    value={form.values.price || undefined}
                    placeholder={t("categoryItemBidFormText11")!}
                    onChange={(value) => form.setFieldValue("price", value)}
                    error={form.touched.price && form.errors.price ? t("categoryItemBidFormText12") : ""}
                  />
                </Group>
                <Space h={15} />
                <Group justify="center">
                  <Button onClick={() => form.handleSubmit()} disabled={!form.values.price}>
                    {t("categoryItemBidFormText13")}
                  </Button>
                  <Button
                    color="red"
                    ref={cancelButtonHover.ref}
                    variant={cancelButtonHover.hovered ? "filled" : "outline"}
                    onClick={form.cancel}
                  >
                    {t("categoryItemBidFormText16")}
                  </Button>
                </Group>
              </FadeTransition>
            </Conditional>
          </Card>
        </FadeTransition>
      </Conditional>
    </>
  );
};

const Tr = styled(Table.Tr)`
  .price-input {
    max-width: 135px;
  }

  .actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export default CategoryItemBidForm;
